<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <div>
    <list-page
        :columns="columns"
        :headers="headers"
        :show-add-button="false"
        sort="id"
        :descending="true"
        title="Site Logs"
        icon="mdi-lock"
        stateendpoint="siteLog.siteLogs"
        :actions="actions"
        :show-default-action-buttons="false"
        :action-buttons="actionButton"
        :show-clear-button="true"
        clear-button-text="Clear Site Logs"
    />
  </div>
</template>
<script>
import ListPage from "../../components/ListPage";

export default {
  name: 'SiteLogs',
  metaInfo: {
    title: 'webexpressmedia.com',
    titleTemplate: 'Admin Dashboard - Site Logs | %s',
    meta: [
      { name: 'robots', content: 'noindex' },
      { name: 'googlebot', content: 'noindex' }
    ]
  },
  components: {ListPage},
  data() {
    return {
      headers: [
        {
          text: 'Logs',
          value: 'logs'
        },
        {
          text: 'Error At',
          align: 'right',
          value: 'created_at'
        },
        {
          text: '',
          value: 'actions',
          sortable: false
        }
      ],
      columns: [
        {value: 'logs'},
        {value: 'action'}
      ],
      actions: {
        load: 'loadSiteLogs',
        destroy: 'destroySiteLog',
        clearData: 'clearSiteLogs'
      },
      actionButton: [
        {
          category: 'delete',
          icon: 'mdi-delete',
          color: 'error',
          text: 'Delete',
          clickHandler: 'destroyItem'
        },
      ]
    }
  }
}
</script>
